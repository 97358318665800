<template>
  <div>
    <div class="content content-fixed bd-b">
        <div :class="`container pd-x-0 pd-lg-x-10 pd-xl-x-0 ${getScreenWidth()<=992?'mg-t-60':''}`">
            <div class="d-sm-flex align-items-center justify-content-between">
                <div>
                    <div v-if="isLogged">
                        <button @click="doAction(a)" v-for="(a,i) in actions" :key="i" role="button" class="btn btn-primary mg-r-5">{{a.name}}</button>
                         төлөвт бүртгэх
                    </div>                    
                </div>
                <div class="mg-t-20 mg-sm-t-0">
                    <button @click="logout()" v-if="isLogged" role="button" class="btn btn-warning"> Гарах</button>
                    <button @click="openLogin()" v-if="!isLogged" role="button" class="btn btn-primary"> Нэвтрэх</button>
                </div>
            </div>
        </div>
    </div>
    <div class="content tx-13">
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <table class="table table-bordered table-striped">
                <tbody>
                    <tr v-for="(r,i) in infs" :key="i">
                        <td scope="col" class="text-right">{{r.name}}</td>
                        <td v-if="r.attach">
                            <a target="_blank" :href="r.value" class="btn btn-xs btn-outline-success">
                                <i  data-feather="circle"></i>
                                Файл харах
                            </a>
                        </td>
                        <td v-else-if="r.link">
                            <a target="_blank" :href="r.value" class="btn btn-xs btn-primary btn-uppercase">
                                <i  data-feather="circle"></i>
                                Эрх Шалгах
                            </a>
                        </td>
                        <td v-else :class="`${r.class}`">{{r.value}}</td>
                    </tr>
                </tbody>
            </table>
            <!-- <iframe :src="dtl.obj.qrFile" class="wd-100p" style="height: 70vh"></iframe> -->

            <!-- <div>Төлөв: <span :style="`color: ${statusValue}`">{{dtl.obj.status}}</span></div> -->
        </div>
    </div>




    <div v-if="showLogin" class="modal fade show" style="display: block; padding-right: 17px;">
      <div class="modal-dialog" role="document">
        <div class="modal-content tx-14">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">Нэвтрэх</h6>
            <button @click="showLogin=false" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
                <label>Нэвтрэх нэр</label>
                <input v-model="loginModel.username" type="text" class="form-control">
             </div>
             <div class="form-group">
                <label>Нууц үг</label>
                <input v-model="loginModel.pass" type="password" class="form-control" />
             </div>
          </div>
          <div class="modal-footer">
            <button @click="doLogin()" class="btn btn-primary">Нэвтрэх</button>        
          </div>
        </div>
      </div>
    </div>
    <div v-if="showLogin" @click="showLogin=false" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { request } from '@/api/all';
import { mapActions } from "vuex";
import { getToken, setToken } from "@/libs/util";
import Cookies from "js-cookie";
import { hasOneOf } from "@/libs/tools";

var _feather= window.feather;
export default {
  data(){
      return{
        accesses: ['tadsstatussharga', 'tadsstatus3haalt', 'tadsstatuscholoot', 'tadsstatuscrossborder', 'tadsstatusenterborder'],
        myAccesses:[],
        myAccessesRdy: false,
        isLogged: false,
        dtl:{
              obj:{},
              rdy: false
        }
        ,
        showLogin: false,
        loginModel:{
            username: '',
            pass: ''
        },

        actions: [],
        infs: [],
      }
  },
  mounted(){
    this.configure();
    this.init();
  },
  methods:{
      ...mapActions(["handleLoading"]),
    configure(){
        //console.log(getToken());
        if(getToken()){
            this.handleLoading({ isLoading: true });
            request({
                    api: { method: 'GET', url: 'User/get_info'},
                    params: { token: getToken() },
                    store: this.$store,
                }).then(res => {
                this.handleLoading({ isLoading: false });
                if (res !== false) {
                    this.isLogged= true;
                    this.myAccesses=res.data.access;
                    this.myAccessesRdy= true;
                    this.configureAfter();
                }
            });
        }else{
            this.isLogged= false;
            this.myAccessesRdy= true;
        }
    },
    configureAfter(){
        if(hasOneOf(this.accesses, this.myAccesses)){
            this.handleLoading({ isLoading: true });
            request({
                api: { method: 'GET', url: 'Tads/StatusList'},
                store: this.$store,
                }).then(res => {
                this.handleLoading({ isLoading: false });
                if (res !== false) {
                    this.actions= res.data.list.filter(x=>hasOneOf(this.myAccesses, [x.role]));
                    console.log(res);
                }
            });      
        }else{
            this.isLogged= false;
            this.myAccessesRdy= false;
            alert('Хандах эрхгүй байна');
            Cookies.remove('token');
        }
    },
    init(){
      this.handleLoading({ isLoading: true });
      request({
        api: { method: 'GET', url: 'Tads/Detail'},
        params: {id: this.$route.params.id},
        store: this.$store,
        }).then(res => {
          this.handleLoading({ isLoading: false });
          if (res !== false) {
            this.dtl.obj= res.data.detail;
            this.dtl.rdy= true;
        console.log(res);

            this.initAfter();
        }
      });
    },
    initAfter(){
        this.infs=[];
        //console.log();
        this.infs.push({name: 'Улсын дугаар', value: this.dtl.obj.plateNo, class: 'tx-16 font-weight-bold'});
        this.infs.push({name: 'Марк', value: this.dtl.obj.mark, class: ''});
        this.infs.push({name: 'Арлын дугаар', value: this.dtl.obj.vin, class: ''});
        this.infs.push({name: 'Дараалал', value: this.dtl.obj.ord, class: 'tx-18 font-weight-bold'});
        this.infs.push({name: 'Машины дараа', value: this.dtl.obj.queueCount, class: 'tx-18 font-weight-bold'});
        this.infs.push({name: 'Төлөв', value: this.dtl.obj.status, class: ''});
        this.infs.push({name: 'Илгээсэн огноо', value: this.dtl.obj.createdDate, class: ''});

        this.infs.push({name: 'Жолоочийн мэдээлэл', value: this.dtl.obj.lastName+ ' ' +this.dtl.obj.firstName, class: ''});
        this.infs.push({name: 'Регистр №', value: this.dtl.obj.regNo, class: ''});
        this.infs.push({name: 'Утас', value: this.dtl.obj.mobile, class: ''});
        this.infs.push({name: 'Хаяг', value: this.dtl.obj.address, class: ''});

        this.infs.push({name: 'Жолооны үнэмлэх', value: this.dtl.obj.defaultAttachment, class: '', attach: true});
        this.infs.push({name: 'Тээврийн хэрэгслийн гэрчилгээ', value: this.dtl.obj.secondAttachment, class: '', attach: true});
        this.infs.push({name: 'Вакцины гэрчилгээ', value: this.dtl.obj.thirdAttachment, class: '', attach: true});

        this.infs.push({name: 'Мэргэшсэн жолоочийн мэдээлэл', value: 'https://www.transdep.mn/check_pd.php', class: '', link: true});

        _feather.replace();
    },
    openLogin(){
      this.showLogin= true;
    },
    doLogin(){
        this.handleLoading({ isLoading: true });
        request({
        api: { method: 'post', url: 'User/LoginApi'  },
        params:{
            user: this.loginModel.username,
            password: this.loginModel.pass,
            key: 'aal8X8oVMkW9CVK0bwCS3g'
        },
        store: this.$store
        }).then(res => {
        this.handleLoading({ isLoading: false });
        if (res !== false) {
            setToken(res.token);
            this.showLogin= false;
            this.configure();
        }
        });
    },
    logout(){
        Cookies.remove('token');
        this.configure();
    },
    doAction(a){
        var result = confirm("Итгэлтэй байна уу?");
        if (result) {
            this.handleLoading({ isLoading: true });
            request({
            api: { method: 'POST', url: 'tads/ChangeStatus' },
            data: {
                id: this.$route.params.id,
                statusId: a.statusId
            },
            store: this.$store
            }).then(res => {
            this.handleLoading({ isLoading: false });
            if (res !== false) {
                alert('Амилттай');
                this.init();
                //console.log(res);
            }
            });   
        }
    },
    getScreenWidth(){
        return screen.width;
    }
  }
}
</script>